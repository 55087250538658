@font-face {
  font-family: "Poppins-Bold";
  src: url("../assets/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Avenir";
  src: url("../assets/fonts/AvenirLTStd-Roman.otf");
}

h1,
h2,
h3,
h4,
h5,
h6,
ion-list-header,
ion-title,
ion-button,
ion-item button {
  font-family: "Poppins-Bold" !important;
}

ion-title {
  padding: 0px 35px;
  font-size: 19px;
}

:root {
  --ion-font-family: "Avenir";
}
