.login-container {
  $space: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  *:before,
  *:after {
    box-sizing: border-box;
  }

  .body {
    display: flex;
    flex-direction: column;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--ion-color-light);
    border-radius: 12px;
  }

  .form__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: ($space * 2);
  }

  #ion-item-mail {
    border-radius: 12px 12px 0px 0px;
  }
  #ion-item-password {
    border-radius: 0px 0px 12px 12px;
  }
  .form__content__header {
    text-align: center;
    margin: 0 0 ($space * 2);
    color: var(--ion-color-tertiary);
  }

  .form__content__heading {
    margin: 0 0 ($space * 0.5);
    color: var(--ion-color-tertiary);
  }

  .form__content__description {
    font-size: 0.875rem;
  }

  .form__field {
    margin-bottom: $space * 2;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .form__submit {
    text-align: center;
  }

  .form__submit a {
    font-size: 0.875rem;
    cursor: pointer;
  }

  .cta {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: $space * 2;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  @media (min-width: 48rem) {
    .body {
      flex-direction: row;
    }

    .form {
      margin: 0;
      box-shadow: none;
    }

    .form__content {
      width: $space * 26;
    }

    .cta__content {
      max-width: 32rem;
      margin: auto;
    }

    .body {
      padding: ($space * 4);
    }
  }

  @media (min-width: 72rem) {
    .form__content {
      width: $space * 32;
      padding: ($space * 4);
    }
    .body {
      padding: ($space * 6);
    }
  }
}
