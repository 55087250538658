.item-availability-badge {
  margin: 5px;
  display: flex;
}

.item-progress-bar {
  margin: 3px 0px;
}

ion-fab-button {
  --border-radius: 15px;
}
