.menu {
  max-width: 300px;
}

#banner {
  height: 45px;
  margin-top: 3px;
  margin-left: 18px;
}
#banner-container {
  background-color: #333334;
  height: 44px;
  width: 100%;
}
