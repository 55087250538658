.vehicleview-container {
  .unused {
    color: grey;
  }

  .operating {
    color: #a6eba2 !important;
  }

  .transit {
    color: #ffcc80;
  }

  .wrong-place {
    color: #e3776f;
  }
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;

  &.dot-connected {
    background-color: #a6eba2;
  }
  &.dot-unknown {
    background-color: #ffcc80;
  }
  &.dot-unconnected {
    background-color: #e3776f;
  }
}
