.item-overview-container {
  ion-list-header {
    font-size: 16px;
  }
}

#list-item-by-vehicle {
  background-color: var(--main-bg-color);
}

#vehicle-tool-container {
  margin-bottom: 10px;
}
